import { render, staticRenderFns } from "./TransactionMatchingConfigNew.vue?vue&type=template&id=92e08f6e&scoped=true&"
import script from "./TransactionMatchingConfigNew.vue?vue&type=script&lang=js&"
export * from "./TransactionMatchingConfigNew.vue?vue&type=script&lang=js&"
import style0 from "./TransactionMatchingConfigNew.vue?vue&type=style&index=0&id=92e08f6e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92e08f6e",
  null
  
)

export default component.exports